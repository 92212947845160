<template>
  <header class="flex items-center bg-white py-16 sm:py-0 px-16 sm:px-24 lg:px-32">
    <router-link :to="{ name: 'Dashboard' }">
      <img :src="logoImage" alt="avisfordele" class="w-230">
    </router-link>

    <nav class="hidden sm:flex items-center w-full ml-8">
      <ul class="flex text-grey-400">
        <router-link
          v-for="item in menuItems"
          :key="item.label"
          :to="{ name: item.name, params: item.params }"
          tag="li"
          class="pb-20 pt-22 px-5 mx-16 cursor-pointer border-b-2 border-transparent hover:text-grey hover:border-grey-200 focus:text-grey focus:border-grey-200"
        >
          {{ item.label }}
        </router-link>

        <template v-if="isAdmin">
          <router-link
            :to="{ name: 'Finance' }"
            tag="li"
            class="pb-20 pt-22 px-5 mx-16 cursor-pointer border-b-2 border-transparent hover:text-grey hover:border-grey-200 focus:text-grey focus:border-grey-200"
          >
            Finans
          </router-link>
          <router-link
            :to="{ name: 'AdminUsers' }"
            tag="li"
            class="pb-20 pt-22 px-5 mx-16 cursor-pointer border-b-2 border-transparent hover:text-grey hover:border-grey-200 focus:text-grey focus:border-grey-200"
          >
            Brugere
          </router-link>
          <router-link
            :to="{ name: 'Inventory' }"
            tag="li"
            class="pb-20 pt-22 px-5 mx-16 cursor-pointer border-b-2 border-transparent hover:text-grey hover:border-grey-200 focus:text-grey focus:border-grey-200"
          >
            Lager
          </router-link>
          <router-link
            :to="{ name: 'EmailEditor' }"
            tag="li"
            class="pb-20 pt-22 px-5 mx-16 cursor-pointer border-b-2 border-transparent hover:text-grey hover:border-grey-200 focus:text-grey focus:border-grey-200"
          >
            Emails
          </router-link>
        </template>
      </ul>

      <span
        class="icon-exit ml-auto py-20 px-4 text-xl text-grey-300 hover:text-grey-400 cursor-pointer"
        @click="$router.push({ name: 'Login' })"
      />
    </nav>
  </header>
</template>

<script>
import { enums } from '@/core/enums'
import { mapState } from 'vuex'

export default {
  name: 'Header',

  data () {
    return {
      menuItems: [
        { label: 'Oversigt', name: 'Dashboard' },
        { label: 'Ordrer', name: 'Orders' },
        { label: 'Produkter', name: 'Products' }
      ]
    }
  },

  computed: {
    ...mapState({
      userData: state => state.user.userData
    }),

    logoImage () {
      return require('@/assets/images/paperboy-logo-header.png')
    },

    isAdmin () {
      return this.userData && (this.userData.role === enums.ROLES.ADMIN)
    }
  }
}
</script>
